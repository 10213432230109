// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body > iframe { 
  display: none; 
}

html{
    touch-action: pan-x pan-y;
    height: 100%;
}

* {
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

:root {
  --bg-color: #1B1F22;
  --header-background: #f6f6f7;
  --text-color: #BFC8D0;

  --text-color-d: #BFC8D0;
  --color-border: #f6f6f7;
  --color-nav-border: #5F666B;

}

html[data-theme="dark"] {
  --header-background: #2D3339;
  --input-background: #3C444D;
  --current-day: #C4FF7933;
  --color-border: #444C56;
  --color-no-active: #5F666B;
  --border-class: #5F666B;
  --bg-class-hour: #756E4B;
  --color-class-hour: #8F8E68;

}

html[data-theme="light"] {
  --header-background: #F6F6F7;
  --input-background: #fff;
  --text-color: #464646;
  --current-day: #D0E9BC;
  --color-no-active: #C6C6C6;
  --border-class:#C0C0C0;
  --bg-class-hour:#FFEBA1;
  --color-class-hour: #C2B47E;
}

body {
  margin: 0;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-color);
  overflow-x: hidden;
  height: 100vh;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;EACE,aAAa;AACf;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB;;AAEA;EACE,sBAAsB;EACtB,iCAAiC;EACjC,0CAA0C;AAC5C;;AAEA;EACE,mBAAmB;EACnB,4BAA4B;EAC5B,qBAAqB;;EAErB,uBAAuB;EACvB,uBAAuB;EACvB,2BAA2B;;AAE7B;;AAEA;EACE,4BAA4B;EAC5B,2BAA2B;EAC3B,wBAAwB;EACxB,uBAAuB;EACvB,0BAA0B;EAC1B,uBAAuB;EACvB,wBAAwB;EACxB,2BAA2B;;AAE7B;;AAEA;EACE,4BAA4B;EAC5B,wBAAwB;EACxB,qBAAqB;EACrB,sBAAsB;EACtB,0BAA0B;EAC1B,sBAAsB;EACtB,uBAAuB;EACvB,2BAA2B;AAC7B;;AAEA;EACE,SAAS;EACT,gBAAgB;EAChB,mCAAmC;EACnC,kCAAkC;EAClC,iCAAiC;EACjC,kBAAkB;EAClB,aAAa;AACf","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap\");\n\nbody > iframe { \n  display: none; \n}\n\nhtml{\n    touch-action: pan-x pan-y;\n    height: 100%;\n}\n\n* {\n  box-sizing: border-box;\n  font-family: \"Roboto\", sans-serif;\n  -webkit-tap-highlight-color: rgba(0,0,0,0);\n}\n\n:root {\n  --bg-color: #1B1F22;\n  --header-background: #f6f6f7;\n  --text-color: #BFC8D0;\n\n  --text-color-d: #BFC8D0;\n  --color-border: #f6f6f7;\n  --color-nav-border: #5F666B;\n\n}\n\nhtml[data-theme=\"dark\"] {\n  --header-background: #2D3339;\n  --input-background: #3C444D;\n  --current-day: #C4FF7933;\n  --color-border: #444C56;\n  --color-no-active: #5F666B;\n  --border-class: #5F666B;\n  --bg-class-hour: #756E4B;\n  --color-class-hour: #8F8E68;\n\n}\n\nhtml[data-theme=\"light\"] {\n  --header-background: #F6F6F7;\n  --input-background: #fff;\n  --text-color: #464646;\n  --current-day: #D0E9BC;\n  --color-no-active: #C6C6C6;\n  --border-class:#C0C0C0;\n  --bg-class-hour:#FFEBA1;\n  --color-class-hour: #C2B47E;\n}\n\nbody {\n  margin: 0;\n  font-weight: 400;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  background-color: var(--bg-color);\n  overflow-x: hidden;\n  height: 100vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
