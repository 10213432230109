// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header_headerWrapper__AEqXI {
  position: sticky;
  top: 0;
  z-index: 10;
}
.header_header__z8ezc {
  position: relative;
  display: flex;
  max-width: 100%;
  flex-direction: column;
  background: var(--header-background);
  border: 1px solid var(--color-border);
  border-radius: 25px;
  padding-inline: 20px;
  padding-top: 100px;
  padding-bottom: 30px;
  gap: 15px;
  height: 347px;
  justify-content: flex-end;
  align-items: center;
  transition: all 0.2s;
  margin: 5px;
  box-sizing: border-box;
}
.header_title__j5wSX {
  font-size: 32px;
  font-weight: 300;
  transition: all 0.2s;
  color: var(--text-color);
}
.header_title__j5wSX p {
  margin: 0;
}
.header_text__0xFdP {
  font-weight: 600;
}
`, "",{"version":3,"sources":["webpack://./src/components/header/header.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,MAAM;EACN,WAAW;AACb;AACA;EACE,kBAAkB;EAClB,aAAa;EACb,eAAe;EACf,sBAAsB;EACtB,oCAAoC;EACpC,qCAAqC;EACrC,mBAAmB;EACnB,oBAAoB;EACpB,kBAAkB;EAClB,oBAAoB;EACpB,SAAS;EACT,aAAa;EACb,yBAAyB;EACzB,mBAAmB;EACnB,oBAAoB;EACpB,WAAW;EACX,sBAAsB;AACxB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,oBAAoB;EACpB,wBAAwB;AAC1B;AACA;EACE,SAAS;AACX;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".headerWrapper {\n  position: sticky;\n  top: 0;\n  z-index: 10;\n}\n.header {\n  position: relative;\n  display: flex;\n  max-width: 100%;\n  flex-direction: column;\n  background: var(--header-background);\n  border: 1px solid var(--color-border);\n  border-radius: 25px;\n  padding-inline: 20px;\n  padding-top: 100px;\n  padding-bottom: 30px;\n  gap: 15px;\n  height: 347px;\n  justify-content: flex-end;\n  align-items: center;\n  transition: all 0.2s;\n  margin: 5px;\n  box-sizing: border-box;\n}\n.title {\n  font-size: 32px;\n  font-weight: 300;\n  transition: all 0.2s;\n  color: var(--text-color);\n}\n.title p {\n  margin: 0;\n}\n.text {\n  font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerWrapper": `header_headerWrapper__AEqXI`,
	"header": `header_header__z8ezc`,
	"title": `header_title__j5wSX`,
	"text": `header_text__0xFdP`
};
export default ___CSS_LOADER_EXPORT___;
