// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu_menu__uksdi {
  height: 0px;
  display: none;
  transition: all 0.3s ease-in-out;
  box-sizing: border-box;
  color: var(--text-color-d);
  width: 100%;
  opacity: 0;
  overflow: hidden;
}

.menu_open__oRGeA {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  opacity: 1;
  gap: 10px;
}

.menu_menu__uksdi p {
  font-size: 16px;
  font-weight: 300;
  margin: 0;
  opacity: inherit;
  transition: all 0.05s ease-in-out;
}
`, "",{"version":3,"sources":["webpack://./src/components/menu/menu.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,gCAAgC;EAChC,sBAAsB;EACtB,0BAA0B;EAC1B,WAAW;EACX,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,2BAA2B;EAC3B,UAAU;EACV,SAAS;AACX;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,SAAS;EACT,gBAAgB;EAChB,iCAAiC;AACnC","sourcesContent":[".menu {\n  height: 0px;\n  display: none;\n  transition: all 0.3s ease-in-out;\n  box-sizing: border-box;\n  color: var(--text-color-d);\n  width: 100%;\n  opacity: 0;\n  overflow: hidden;\n}\n\n.open {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: flex-start;\n  opacity: 1;\n  gap: 10px;\n}\n\n.menu p {\n  font-size: 16px;\n  font-weight: 300;\n  margin: 0;\n  opacity: inherit;\n  transition: all 0.05s ease-in-out;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu": `menu_menu__uksdi`,
	"open": `menu_open__oRGeA`
};
export default ___CSS_LOADER_EXPORT___;
