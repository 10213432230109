// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.class_class__Ia8CF {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-bottom: 1px solid var(--border-class);
  padding: 9px 0;
  gap: 10px;
}
.class_class__Ia8CF:nth-child(1) {
  border-top: 0.5px solid var(--border-class);
}

.class_info__J5LXi {
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1 1;
}

.class_course__mx-6F {
  color: inherit;
  padding: 7px 10px;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  text-align: left;
}
.class_time__Aul\\+x {
  color: inherit;
  padding: 7px 0px;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  text-align: left;
}

.class_rooms__epKPZ {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.class_hour__hKi43 {
  background: var(--bg-class-hour);
  color: var(--color-class-hour);
  border-radius: 10px;
}
.class_extra__RBZc6 {
  width: 15px;
  height: 16px;
  padding: 2px 4px;
  font-size: 10px;
  line-height: 12.1px;

  border-radius: 5px;
  border: 1px solid var(--border-class);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-left: 3px;
}
`, "",{"version":3,"sources":["webpack://./src/components/class/class.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,kBAAkB;EAClB,4CAA4C;EAC5C,cAAc;EACd,SAAS;AACX;AACA;EACE,2CAA2C;AAC7C;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,SAAO;AACT;;AAEA;EACE,cAAc;EACd,iBAAiB;EACjB,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,oBAAoB;EACpB,gBAAgB;AAClB;AACA;EACE,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,oBAAoB;EACpB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,yBAAyB;AAC3B;AACA;EACE,gCAAgC;EAChC,8BAA8B;EAC9B,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,eAAe;EACf,mBAAmB;;EAEnB,kBAAkB;EAClB,qCAAqC;EACrC,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":[".class {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  position: relative;\n  border-bottom: 1px solid var(--border-class);\n  padding: 9px 0;\n  gap: 10px;\n}\n.class:nth-child(1) {\n  border-top: 0.5px solid var(--border-class);\n}\n\n.info {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n  flex: 1;\n}\n\n.course {\n  color: inherit;\n  padding: 7px 10px;\n  width: 100%;\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 18.75px;\n  text-align: left;\n}\n.time {\n  color: inherit;\n  padding: 7px 0px;\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 18.75px;\n  text-align: left;\n}\n\n.rooms {\n  text-align: right;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n}\n.hour {\n  background: var(--bg-class-hour);\n  color: var(--color-class-hour);\n  border-radius: 10px;\n}\n.extra {\n  width: 15px;\n  height: 16px;\n  padding: 2px 4px;\n  font-size: 10px;\n  line-height: 12.1px;\n\n  border-radius: 5px;\n  border: 1px solid var(--border-class);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n  margin-left: 3px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"class": `class_class__Ia8CF`,
	"info": `class_info__J5LXi`,
	"course": `class_course__mx-6F`,
	"time": `class_time__Aul+x`,
	"rooms": `class_rooms__epKPZ`,
	"hour": `class_hour__hKi43`,
	"extra": `class_extra__RBZc6`
};
export default ___CSS_LOADER_EXPORT___;
