// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app_app__ZSdIq {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: top;
  overflow-x: hidden;
  height: 100vh;
  position: relative;
}
.app_text__mJfTI {
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #bfc8d0;
  font-size: 20px;
  font-weight: 400;
}
`, "",{"version":3,"sources":["webpack://./src/components/app/app.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,2BAA2B;EAC3B,sBAAsB;EACtB,gBAAgB;EAChB,kBAAkB;EAClB,aAAa;EACb,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".app {\n  display: flex;\n  justify-content: flex-start;\n  flex-direction: column;\n  align-items: top;\n  overflow-x: hidden;\n  height: 100vh;\n  position: relative;\n}\n.text {\n  position: absolute;\n  text-align: center;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  color: #bfc8d0;\n  font-size: 20px;\n  font-weight: 400;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"app": `app_app__ZSdIq`,
	"text": `app_text__mJfTI`
};
export default ___CSS_LOADER_EXPORT___;
