// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapperBurgerMenu {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(30%, 30%);
}

.burger-menu {
  position: relative;
  width: 50px;
  height: 50px;
  padding: 14px;
  border: 1px solid var(--text-color-d);
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
  display: none;
  outline: none;
}

#line1,
#line2,
#line3,
#line4,
#line5 {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 21px;
  height: 3px;
  background-color: var(--text-color-d);
  transition: all 0.3s ease;
}

#line1 {
  transform: translate(-50%, -8px);
}

#line2 {
  transform: translate(-50%, -45%);
}

#line3 {
  transform: translate(-50%, 5px);
}

#line4 {
  transform: translate(-50%, -45%) rotate(-45deg);
  display: none;
}
#line5 {
  transform: translate(-50%, -45%) rotate(45deg);
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/burger-menu/burger-menu.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,8BAA8B;AAChC;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,qCAAqC;EACrC,kBAAkB;EAClB,6BAA6B;EAC7B,eAAe;EACf,aAAa;EACb,aAAa;AACf;;AAEA;;;;;EAKE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,cAAc;EACd,WAAW;EACX,WAAW;EACX,qCAAqC;EACrC,yBAAyB;AAC3B;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,+CAA+C;EAC/C,aAAa;AACf;AACA;EACE,8CAA8C;EAC9C,aAAa;AACf","sourcesContent":[".wrapperBurgerMenu {\n  position: absolute;\n  top: 0;\n  left: 0;\n  transform: translate(30%, 30%);\n}\n\n.burger-menu {\n  position: relative;\n  width: 50px;\n  height: 50px;\n  padding: 14px;\n  border: 1px solid var(--text-color-d);\n  border-radius: 50%;\n  background-color: transparent;\n  cursor: pointer;\n  display: none;\n  outline: none;\n}\n\n#line1,\n#line2,\n#line3,\n#line4,\n#line5 {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  display: block;\n  width: 21px;\n  height: 3px;\n  background-color: var(--text-color-d);\n  transition: all 0.3s ease;\n}\n\n#line1 {\n  transform: translate(-50%, -8px);\n}\n\n#line2 {\n  transform: translate(-50%, -45%);\n}\n\n#line3 {\n  transform: translate(-50%, 5px);\n}\n\n#line4 {\n  transform: translate(-50%, -45%) rotate(-45deg);\n  display: none;\n}\n#line5 {\n  transform: translate(-50%, -45%) rotate(45deg);\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
